import React, { useState } from "react"

import "./kontakt.styl"

// TODO remove empty p and replace with style margin
// TODO send Mail/Ticket

const formLink = "https://form.alloy.qnipp.com/process.php"

const validateForm = form => {
  // validate
  const result = { success: [], error: [] }
  const fd = new FormData(form)

  const missingValue = {
    name: "Dein Name fehlt.",
    text: "Dein Anliegen wurde nicht angegeben.",
    email: "Es wurde keine E-Mail Adresse angegeben.",
  }

  for (var data of fd.entries()) {
    const [key, value] = data

    if (!value) {
      result.error.push(missingValue[key])
    }

    // validate email
    if (
      value &&
      key === "email" &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value)
    ) {
      result.error.push("Es wurde keine gültige E-Mail Adresse angegeben.")
    }
  }

  // if (result.error.length === 0) {
  //   result.success.push(
  //     "Die Anfrage wurde erfolgreich versendet. Wir werden Sie demnächst Kontaktieren."
  //   )
  // }

  return result
}

const Formular = () => {
  const [result, setResult] = useState({ success: [], error: [] })
  const [isRequestSended, setIsRequestSended] = useState(false)

  const onSubmit = e => {
    e.preventDefault()

    const validate = validateForm(e.target)
    if (validate.error.length === 0) {
      e.target.action = formLink
      const fd = new FormData(e.target)
      fd.append("form_tools_form_id", "11")

      fetch(formLink, {
        method: "POST",
        redirect: "manual",
        body: fd,
      }).then(response => {
        if (response.type === "opaqueredirect") {
          // data successfull
          // console.log("succesful")
          setResult({
            error: [],
            success: [
              "Die Anfrage wurde erfolgreich versendet. Wir werden Sie demnächst kontaktieren.",
            ],
          })
          setIsRequestSended(true)
        } else {
          // data failed
          // console.log("failed")
          setResult({
            error: [
              "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
            ],
            success: [],
          })
        }
      })
    }
    setResult(validate)
  }

  return (
    <form
      id="contact-form"
      className="uk-width-1-2 uk-padding"
      method="post"
      onSubmit={onSubmit}
    >
      <h3>Ich habe folgendes Anliegen:</h3>
      <textarea className="uk-textarea" name="text" placeholder="Anliegen..." rows="5" />
      <p>
        <b>Mein Name:</b>
      </p>
      <input className="uk-input" name="name" placeholder="Name" />
      <p>
        <b>Meine E-Mail Adresse:</b>
      </p>
      <input className="uk-input" name="email" placeholder="Email" />
      <button id="anfrage-button" className="uk-margin" disabled={isRequestSended}>
        ANFRAGEN
      </button>
      {result.error.map(err => (
        <p key={err} style={{ marginTop: "12px" }}>
          {err}
        </p>
      ))}
      {result.success.map(suc => (
        <p key={suc} style={{ marginTop: "12px" }}>
          {suc}
        </p>
      ))}
    </form>
  )
}

export default () => (
  <div id="contact" className="main-page">
    <h1 className="page-title">Kontakt</h1>
    <div className="uk-grid">
      <div className="uk-width-1-2">
        <h3>Apotheke Loosdorf</h3>
        Wiener Str. 12 <br />
        3382 Loosdorf <br />
        Österreich <br />
        <br />
        Tel.: <a href="tel:+4327546331">+43 27 54/63 31</a> <br />
        Fax: +43 27 54/63 31-9 <br />
        <a href="mailto:office@apotheke-loosdorf.at ">
          office@apotheke-loosdorf.at
        </a>
        <br />
        <h3>Öffnungszeiten</h3>
        Mo-Fr 8:00-18:00 Uhr <br />
        Sa 8:00-12:00 Uhr
      </div>
      <Formular />
    </div>

    <h2 className="section-title">Anfahrt</h2>
    <div className="uk-grid section-bg">
      <div className="uk-width-2-2 google-maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d664.8331340360329!2d15.401892897543593!3d48.20021044909691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb6097cb374c97a0!2sApotheke+Zur+Heimat!5e0!3m2!1sen!2sat!4v1527020129398"
          title="Anfahrt"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
        />
      </div>
    </div>
  </div>
)
